@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Courier New", Courier, monospace;
}

/* Typing effect without blinking cursor */
.typing-effect {
  white-space: nowrap;
  overflow: hidden;
  width: fit-content;
  animation: typing 2.5s steps(22, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Smaller and blinking pipe at the end, properly aligned */
.small-blinking-pipe {
  font-size: 0.8em;
  animation: blink 1s infinite step-start;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: -0.15em;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Glitch Effect */
.hacker-button {
  position: relative;
  color: green;
  border: 2px solid green;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-shadow: 0 0 5px green, 0 0 10px lime, 0 0 20px lime;
  box-shadow: 0 0 10px green;
  transition: 0.2s ease-in-out;
  animation: glow 2s infinite alternate;
}

.hacker-button:hover {
  background: black;
  color: lime;
  box-shadow: 0 0 15px lime, 0 0 20px lime;
  text-shadow: 0 0 10px lime, 0 0 15px lime;
}

.hacker-button::before,
.hacker-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  color: lime;
  z-index: -1;
  clip: rect(0, 0, 0, 0);
}

.hacker-button:hover::before {
  clip: rect(0, 900px, 200px, 0);
  animation: glitch 0.3s linear infinite;
}

.hacker-button:hover::after {
  clip: rect(200px, 900px, 0, 0);
  animation: glitch 0.3s linear infinite reverse;
}

@keyframes glitch {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(2px, -2px);
  }
  40% {
    transform: translate(-2px, 2px);
  }
  60% {
    transform: translate(1px, -1px);
  }
  80% {
    transform: translate(-1px, 1px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/* Glow effect for hacker button */
@keyframes glow {
  0% {
    text-shadow: 0 0 5px green, 0 0 10px lime, 0 0 20px lime;
    box-shadow: 0 0 10px green, 0 0 20px lime;
  }
  100% {
    text-shadow: 0 0 10px lime, 0 0 20px lime, 0 0 30px lime;
    box-shadow: 0 0 20px lime, 0 0 30px lime;
  }
}

/* Terminal box styling */
.terminal-box {
  box-shadow: 0px 0px 20px green;
  border-radius: 10px;
  padding: 20px;
}

/* Neon red shadow and text for invalid input */
.invalid-domain {
  text-shadow: 0 0 5px red, 0 0 10px red, 0 0 20px red;
  box-shadow: 0 0 10px red !important;
}

/* Terminal output text styling */
.terminal-output {
  text-shadow: 0px 0px 5px green;
}

/* Focus effect only on search input */
input:focus {
  border: 2px solid lime;
  box-shadow: 0 0 10px lime, 0 0 20px lime;
  outline: none;
  transition: all 0.3s ease-in-out;
}

/* Styling for the glitchy hacker-style links */
.hacker-link {
  color: green;
  text-decoration: none;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
  display: inline-block;
  transition: color 0.2s ease-in-out;
}

.hacker-link:hover {
  color: lime;
  text-shadow: 0 0 5px green, 0 0 10px lime, 0 0 20px lime;
}

.hacker-link::before,
.hacker-link::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;
  color: lime;
  z-index: -1;
  clip: rect(0, 0, 0, 0);
}

.hacker-link:hover::before {
  clip: rect(0, 900px, 200px, 0);
  animation: glitch 0.2s linear infinite;
}

.hacker-link:hover::after {
  clip: rect(200px, 900px, 0, 0);
  animation: glitch 0.2s linear infinite reverse;
}

@keyframes glitch {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(2px, -2px);
  }
  40% {
    transform: translate(-2px, 2px);
  }
  60% {
    transform: translate(1px, -1px);
  }
  80% {
    transform: translate(-1px, 1px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/* Responsive Padding, Text Sizes, and Layout Adjustments */
@media (min-width: 1024px) {
  /* Desktop screens */
  .terminal-box {
    padding: 30px;
  }
  .terminal-output {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* Tablet screens */
  .terminal-box {
    padding: 20px;
  }
  .typing-effect {
    font-size: 3rem;
  }
  .terminal-output {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  /* Mobile screens */
  .terminal-box {
    padding: 15px;
  }
  .typing-effect {
    font-size: 2.5rem;
  }
  .terminal-output {
    font-size: 0.9rem;
  }
  .hacker-button {
    width: 100%;
    text-align: center;
  }
}
